<template>
    <section class="container" style="max-width: 100%; padding: 0;">
      <div class="card-container">
        <aside>Numer karty</aside>
        <div
          id="payu-card-number"
          class="payu-card-form"
        />

        <div class="card-details clearfix">
          <div class="expiration">
            <aside>Data ważności</aside>
            <div
              id="payu-card-date"
              class="payu-card-form"
            />
          </div>

          <div class="cvv">
            <aside>CVV</aside>
            <div
              id="payu-card-cvv"
              class="payu-card-form"
            />
          </div>
        </div>
      </div>
      <button
          id="tokenizeButton"
          class="d-none"
          ref="submitCardButton"
      >
        Tokenize
      </button>
      <button
          class="d-none"
          id="purcacheButton"
          ref="purcacheButton"
      >
        Purcache
      </button>
      <div id="responseTokenize" />
    </section>
</template>

<script defer>
export default {
  name: 'PayuSecureForm',
  data() {
    return {
      orderData: [],
      subscribeNoAuth: 'subscribe-no-auth',
      subscribe: 'subscribe',
    }
  },
  computed: {
    development() {
      return process.env.NODE_ENV === 'development'
    },
    subscribeApi() {
      return !localStorage.accessToken ? this.subscribeNoAuth : this.subscribe;
    }
  },
  beforeMount() {
    let payuScript = document.createElement('script')
    payuScript.setAttribute('src', this.development
        ? 'https://secure.snd.payu.com/javascript/sdk' : 'https://secure.payu.com/javascript/sdk')
    document.head.appendChild(payuScript)
  },
  mounted() {
    setTimeout(() => {
      const optionsForms = {
        cardIcon: true,
        style: {
          basic: {
            fontSize: '24px',
          },
        },
        placeholder: {
          number: '',
          date: 'MM/YY',
          cvv: '',
        },
        lang: 'pl',
      }

      const renderError = function (element, errors) {
        element.className = 'alert alert-danger'
        const messages = []
        errors.forEach(error => {
          messages.push(error.message)
        })
        element.innerHTML = '<svg style="color: red" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z" fill="red"></path> <path d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z" fill="red"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z" fill="red"></path> </svg>\n' + messages.join(', ')
      }

      const renderSuccess = (element, msg, orderData = this.orderData) => {
        element.className = 'text-success alert';
        element.innerHTML = '<div class="spinner-border" role="status"><span class="sr-only">Ładowanie...</span></div>' + ' sprawdzanie';
        orderData['token'] = msg ? msg : 'subscription_sell';

        this.$http.post(`${this.$store.state.apiDomain}/api/` + this.subscribeApi, orderData).then(response => {

          if(response.data.redirectUri) {
            element.innerText = 'Przekierowanie'
            window.location.replace(response.data.redirectUri)
          } else {
            element.className = 'alert alert-danger'
            element.innerText = 'Karta nie kwalifikuje się do płatności'
          }
        }).catch(function (error) {
          element.className = 'alert alert-danger'
          element.innerText = error.response.data.message
        });
      }

      // initialize the SDK by providing your POS ID and create secureForms object
      const payuSdkForms = PayU(process.env.VUE_APP_PAYU_POS_ID)
      const secureForms = payuSdkForms.secureForms()

      // create the forms by providing type and options
      const cardNumber = secureForms.add('number', optionsForms)
      const cardDate = secureForms.add('date', optionsForms)
      const cardCvv = secureForms.add('cvv', optionsForms)

      // render the form in selected element
      cardNumber.render('#payu-card-number')
      cardDate.render('#payu-card-date')
      cardCvv.render('#payu-card-cvv')

      const tokenizeButton = document.getElementById('tokenizeButton')
      const responseElement = document.getElementById('responseTokenize')
      const app = this

      tokenizeButton.addEventListener('click', () => {
        responseElement.innerText = ''

        try {
          /// /tokenize the card (communicate with PayU server)
          payuSdkForms.tokenize('MULTI').then(result => { // example for SINGLE type token or MULTI
            result.status === 'SUCCESS'
                ? renderSuccess(responseElement, result.body.token) // pass the token to your back-end and charge it
                : renderError(responseElement, result.error.messages) // check the business error type and messages and display appropriate information to the user
          })
        } catch (e) {
          console.log(e) // technical errors
        }
      })
      purcacheButton.addEventListener('click', () => {
        responseElement.innerText = ''
        renderSuccess(responseElement, null)
      })

    }, 1500)
  }
}
</script>

<style scoped>
.container * {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #ffffff;
}

.container {
  text-align: center;
  width: 420px;
  margin: 20px auto 10px;
  display: block;
  border-radius: 5px;
  box-sizing: border-box;
}

.card-container {
  width: 100%;
  margin: 0 auto;
  border-radius: 6px;
  padding: 10px;
  background: rgb(2,0,60);
  text-align: left;
  box-sizing: border-box;
}

.card-container aside {
  padding-bottom: 6px;
}

.payu-card-form {
  background-color: #ffffff;
  padding: 5px;
  border-radius: 4px;
}

.card-details {
  clear: both;
  overflow: auto;
  margin-top: 10px;
}

.card-details .expiration {
  width: 50%;
  float: left;
  padding-right: 5%;
}

.card-details .cvv {
  width: 45%;
  float: left;
}

button {
  border: none;
  background: #438F29;
  padding: 8px 15px;
  margin: 10px auto;
  cursor: pointer;
}
.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.text-success {
  color: #3c763d!important;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
</style>
